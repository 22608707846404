import { Box } from "@chakra-ui/react";
import ProjectContainer from "./ProjectContainer";
import "./projects.css";
export default function Projects() {
  return (
    <Box id="projects" className="ap-site-slice">
      <Box className="site-Subheader">Projects</Box>

      <Box marginTop={"100px"}>
        <ProjectContainer
          name={"Chefster"}
          summary="A simple recipe website with some features I find useful like inline measurements & scalable recipes with plenty more in the pipeline"
          logoImageName={"chefsterLogo.png"}
          urlLink={"https://chefster.co.uk"}
          frontEndDescription="The front end is put together using a React & Next js, utilising webpack and serverless packages for build & deploy. Deployed onto aws cloudfront via serverless with a few supporting s3 buckets for seperate static assets."
          backEndDescription="The back end is composed of a dotnet core web api using entity framework core to manipulate the data into a database. Deployed onto an aws elastic beanstalk & with an rds databse instance and makes use of gitlab CI/CD pipelines to run intergration & unit tests on each build."
          websitePreviewImage={"ChefsterPreview.png"}
        />

        <ProjectContainer
          name={"Space Invaders"}
          summary={
            "A very quick and rough example of space invaders being done in html 5 canvas and raw javascript. "
          }
          description={
            "I initally started this project as i had a friend who was assigned this as a project on his coding bootcamp and I felt like I was struggling to answer any questions which involved html canvas and my raw javascript knowledge was rather rusty. So why not give the assignment a go!"
          }
          logoImageName="spaceInvadersLogo.png"
          displayGame={true}
        />
      </Box>
    </Box>
  );
}
