import * as React from "react";
import setupEvents from "./js/scrollingNavigationHighlight.js";
import "./navigation.css";
import {
  Box,
  Button,
  HStack,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";

export const NavigationComponent: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  React.useEffect(() => {
    setupEvents();
  });

  const toggleTheme: JSX.Element = (
    <HStack>
      <MoonIcon />
      <Switch isChecked={colorMode === "light"} onChange={toggleColorMode} />
      <SunIcon />
    </HStack>
  );

  const navigationLinks: JSX.Element = (
    <>
      <Link className="nav-link" href="#aboutMe">
        About Me
      </Link>
      <Link className="nav-link" href="#skills">
        Skills
      </Link>
      <Link className="nav-link" href="#employment">
        Employment
      </Link>
      <Link className="nav-link" href="#education">
        Education
      </Link>

      <Link className="nav-link" href="#projects">
        Projects
      </Link>

      <Link className="nav-link" href="#meetups">
        Meetups
      </Link>
    </>
  );

  return (
    <Box className="ap-navigation neo-box pull" zIndex={101}>
      <VStack>
        <Box id="pagePositionWrapper" className="pagePositionWrapper">
          <Box id="pagePosition" className="page-position-bar"></Box>
        </Box>
        <Box
          id="navigationWrapper"
          className="flyingNav"
          style={{ height: "100%" }}
          display={{ base: "none", md: "flex" }}
          width={"100%"}
        >
          <HStack
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            {toggleTheme}
            <HStack>{navigationLinks}</HStack>
            <Box></Box>
          </HStack>
        </Box>

        <HStack
          justifyContent={"space-between"}
          display={{ base: "flex", md: "none" }}
          width={"100%"}
        >
          <Box>{toggleTheme}</Box>
          <Box>
            <Popover>
              <PopoverTrigger>
                <Button>
                  <HamburgerIcon />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody margin={"1px"}>{navigationLinks}</PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default NavigationComponent;
