/* eslint-disable no-undef */
function setPagePosition() {
  var position = Math.ceil(window.scrollY);
  var height = Math.ceil(
    document.getElementsByTagName("BODY")[0].clientHeight - window.innerHeight
  );

  var percent = Math.ceil((position / height) * 100);
  var newValue = percent + "%";
  document
    .getElementById("pagePosition")
    .setAttribute("style", "width:" + newValue);
}

function relocateNavigation() {
  var heightBreakpoint =
    document.getElementsByClassName("ap-header")[0].clientHeight;

  var nav = document.getElementsByClassName("ap-navigation")[0];
  if ($(window).scrollTop() >= heightBreakpoint) {
    $(nav).addClass("ap-header-fixed");
  } else {
    $(nav).removeClass("ap-header-fixed");
  }
}

export function setupEvents() {
  window.onload = function (e) {
    setPagePosition();
  };

  window.addEventListener("scroll", function (e) {
    setPagePosition();
    relocateNavigation();
  });
}

export default setupEvents;
