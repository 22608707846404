export const meetups = [
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 6,
      OrgId: 1,
      EventDate: "1476140400000",
      Description:
        "You\u0027ve ignored Containers long enough now, Microsoft is bringing them to you in Windows Server 2016. Yes, that\u0027s right, you\u0027ll be able to run Containers in Windows using Docker. In this session we\u0027ll look at what a Container is, how to get started and how to deploy an ASP.NET application into one. ",
      Title: "Developing apps in Windows Containers using Docker",
      Url: "https://www.meetup.com/DotNetNorth/events/232943682/",
    },
  },
  {
    Organiser: {
      Id: 3,
      Name: "Lean Agile Manchester",
      LogoUrl: "leanagile",
      Website: "https://www.meetup.com/Lean-Agile-Manchester/",
      Description: null,
    },
    Event: {
      Id: 9,
      OrgId: 3,
      EventDate: "1476831600000",
      Description:
        "The speaker for the evening, Tom Loosemore has considerable expertise in his field, having spent over 20 years making digital products and services. In this talk, he\u0027ll share how he\u0027s gone about introducing an agile mindset and ways of working to more traditional organisations. He\u0027ll be sharing stories of his time working at the BBC, mySociety, Government Digital Service and more recently at the Co-op, where he is Group Director of Digital Services.",
      Title: "Tips on how to introduce Agile to a traditional organisation",
      Url: "https://www.meetup.com/Lean-Agile-Manchester/events/234508790/",
    },
  },
  {
    Organiser: {
      Id: 2,
      Name: "Expert Talks Manchester",
      LogoUrl: "ExpertThingsLogo",
      Website: "https://www.meetup.com/Expert-Talks-Manchester/",
      Description: null,
    },
    Event: {
      Id: 7,
      OrgId: 2,
      EventDate: "1480377600000",
      Description:
        "Join us for a very special Expert Talks on November 29th. Why so special? Because we’ve joining forces with the North West Tester Gathering. Our initial clash of dates has turned into your reward: a bumper crop of great talks, networking and pizza.",
      Title: "ExpertTalks: challenges in modern software testing",
      Url: "https://www.meetup.com/Expert-Talks-Manchester/events/235101010/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 5,
      OrgId: 1,
      EventDate: "1481587200000",
      Description:
        "Microsoft HoloLens is the first fully self-contained holographic computer running Windows 10. It is completely self-contained-no wires, phones, or connection to a PC needed. HoloLens allows you to place holograms in your physical environment and provides a new way to see your world.",
      Title: "Developing with HoloLens: The Path to Mixed Reality",
      Url: "https://www.meetup.com/DotNetNorth/events/232943669/",
    },
  },
  {
    Organiser: {
      Id: 2,
      Name: "Expert Talks Manchester",
      LogoUrl: "ExpertThingsLogo",
      Website: "https://www.meetup.com/Expert-Talks-Manchester/",
      Description: null,
    },
    Event: {
      Id: 8,
      OrgId: 2,
      EventDate: "1487116800000",
      Description:
        "Inspiring better business: ExpertTalks returns to Manchester with two talks sure to inspire your work in 2017: Patterns and anti-patterns in agile org design \u0026 I am a business analyst - and so are you!",
      Title: "ExpertTalks returns to Manchester",
      Url: "https://www.meetup.com/Expert-Talks-Manchester/events/236840340/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 4,
      OrgId: 1,
      EventDate: "1488844800000",
      Description:
        "In a change to the advertised talk, this month we will be hosting Mark Allan (@markxa) who will be giving two separate talks... Azure Functions: The natural evolution of Platform As A Service, Functions As A Service is the cornerstone of the new serverless paradigm.",
      Title: "Azure Functions and DocumentDB",
      Url: "https://www.meetup.com/DotNetNorth/events/234676154/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 3,
      OrgId: 1,
      EventDate: "1494284400000",
      Description:
        "In this talk Pete Vickers from GUI Innovations (and one of the founders of DotNetNorth) talks about how to bring existing desktop apps to the Universal Windows Platform.",
      Title: "Windows Desktop Bridge with Pete Vickers",
      Url: "https://www.meetup.com/DotNetNorth/events/238275532/",
    },
  },
  {
    Organiser: {
      Id: 5,
      Name: "Build the BBC",
      LogoUrl: "BBCLogo",
      Website:
        "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
      Description: null,
    },
    Event: {
      Id: 17,
      OrgId: 5,
      EventDate: "1496185200000",
      Description:
        "Join the Bitesize presenters and learn all about the technical challenges and technical approach on building a scalable educational platform.",
      Title: "Synopsis 1: BBC Bitesize",
      Url: "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
    },
  },
  {
    Organiser: {
      Id: 5,
      Name: "Build the BBC",
      LogoUrl: "BBCLogo",
      Website:
        "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
      Description: null,
    },
    Event: {
      Id: 18,
      OrgId: 5,
      EventDate: "1496271600000",
      Description:
        "Join the Programme Metadata team as they describe their journey of building the microservices and the infrastructure required to build and serve TV schedules at scale.",
      Title: "Synopsis 2: BBC IPlayer",
      Url: "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
    },
  },
  {
    Organiser: {
      Id: 5,
      Name: "Build the BBC",
      LogoUrl: "BBCLogo",
      Website:
        "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
      Description: null,
    },
    Event: {
      Id: 19,
      OrgId: 5,
      EventDate: "1496358000000",
      Description:
        "BBC Terrific Scientific is a campaign aiming to help primary school children discover their inner scientist through a number of collaborative, hands-on investigations. We’ll be taking a look at some of the techniques we used to bring the campaign to life, focussing on the Terrific Scientific map and how it gets its data.",
      Title: "Synopsis 3: BBC Terrific",
      Url: "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
    },
  },
  {
    Organiser: {
      Id: 5,
      Name: "Build the BBC",
      LogoUrl: "BBCLogo",
      Website:
        "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
      Description: null,
    },
    Event: {
      Id: 20,
      OrgId: 5,
      EventDate: "1496444400000",
      Description:
        "Our RESTful APIs handle hundreds of millions of requests per day to power BBC and partner products. Come and find out about our challenges, mistakes and also our plans for the future. As we consider whether RESTful APIs are always the right solution?",
      Title: "Synopsis 4 : BBC API",
      Url: "https://www.eventbrite.co.uk/e/build-the-bbc-software-engineering-at-scale-tickets-34364134050",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 2,
      OrgId: 1,
      EventDate: "1500937200000",
      Description:
        "Keith Williams leads the development team at Sun Branding Solutions, working on software that designs and packages a surprisingly large proportion of the items in your shopping basket.",
      Title: "Real-World .NET Core: Everything You Need to Know",
      Url: "https://www.meetup.com/DotNetNorth/events/239567453/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 1,
      OrgId: 1,
      EventDate: "1502146800000",
      Description:
        "Rider is a new cross platform IDE from JetBrains, integrating the language analysis features of ReSharper inside the IDE functionality of IntelliJ. Wait, what?  ReSharper is a plugin to Visual Studio, running in .NET, and IntelliJ is a JVM application! Cats and dogs, living together, mass hysteria!",
      Title: "Rider - taking ReSharper out of process; with Matt Ellis",
      Url: "https://www.meetup.com/DotNetNorth/events/241018056/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 11,
      OrgId: 1,
      EventDate: "1505170800000",
      Description:
        "ASP.NET Core 2.0 will either be RTM or very nearly so by the time this talk happens. With .NET Core 2.0 and the massive number of new APIs available in .NET Standard 2.0, building new web apps or migrating existing ones is going to be much easier, meaning cross-platform, high-performance applications are within reach for everyone.",
      Title: "ASP.NET Core 2 with Mark Rendle",
      Url: "https://www.meetup.com/DotNetNorth/events/241111326/",
    },
  },
  {
    Organiser: {
      Id: 4,
      Name: "DDD North 2017",
      LogoUrl: "dddnorth",
      Website: "https://www.dddnorth.co.uk",
      Description: null,
    },
    Event: {
      Id: 12,
      OrgId: 4,
      EventDate: "1507935600000",
      Description:
        "This talk goes into my experience during the last year as part of a team building a system that is based on microservics architecture. Whilst I\u0027ll mention the technology used this talk won\u0027t be about the technology more about the lessons I\u0027ve learnt.",
      Title:
        "Microservices - what I\u0027ve learnt after a year of building a system.",
      Url: "http://www.dddnorth.co.uk/Sessions/Details/352",
    },
  },
  {
    Organiser: {
      Id: 4,
      Name: "DDD North 2017",
      LogoUrl: "dddnorth",
      Website: "https://www.dddnorth.co.uk",
      Description: null,
    },
    Event: {
      Id: 13,
      OrgId: 4,
      EventDate: "1508022000000",
      Description:
        "In this session we will get down and dirty with clean code. Expanding on Uncle Bob’s teachings this session will give you practical hints and tips to help you practice clean code. We will cover what clean code is, why you should be writing it and how to write it. If you’ve been a victim of “wtf code”, or even guilty of writing it yourself, then this is a must attend talk for you.",
      Title: "Teaching an old dog new tricks.",
      Url: "http://www.dddnorth.co.uk/Sessions/Details/320",
    },
  },
  {
    Organiser: {
      Id: 4,
      Name: "DDD North 2017",
      LogoUrl: "dddnorth",
      Website: "https://www.dddnorth.co.uk",
      Description: null,
    },
    Event: {
      Id: 14,
      OrgId: 4,
      EventDate: "1508108400000",
      Description:
        "Using agile methodologies like Scrum or XP has become common place in a lot of organizations, but what do you do when you want to scale your business or department in size.  Just being Agile in it self won\u0027t stop the growth of your business and teams descending into chaos. In this talk, we will look at different techniques and team dynamics changes you can make to allow you to scale Agile across your business.",
      Title: "Scaling Agile in your Organization with the Spotify Model.",
      Url: "http://www.dddnorth.co.uk/Sessions/Details/327",
    },
  },
  {
    Organiser: {
      Id: 4,
      Name: "DDD North 2017",
      LogoUrl: "dddnorth",
      Website: "https://www.dddnorth.co.uk",
      Description: null,
    },
    Event: {
      Id: 15,
      OrgId: 4,
      EventDate: "1508194800000",
      Description:
        "We will take a closer look at why we care about async, how it works and then deep dive into async issues. By the end of this session we will understand what SynchronizationContexts are, when to use ConfigureAwait, how deadlocks occur and how to detect and avoid them. On the way we\u0027ll touch on some C#7 features.",
      Title: "Async in C# - The Good, the Bad and the Ugly.",
      Url: "http://www.dddnorth.co.uk/Sessions/Details/379",
    },
  },
  {
    Organiser: {
      Id: 4,
      Name: "DDD North 2017",
      LogoUrl: "dddnorth",
      Website: "https://www.dddnorth.co.uk",
      Description: null,
    },
    Event: {
      Id: 16,
      OrgId: 4,
      EventDate: "1508281200000",
      Description:
        ".NET Core has dominated the hype for a while, but, what’s it really like running in production, on different operating systems? We ported our 15 year old codebase (you read that right) to be running on the shiniest new thing in the .NET world, and yes, we hit some problems. Luckily, we managed to solve or work around those problems successfully.",
      Title: ".NET Core in the real world.",
      Url: "http://www.dddnorth.co.uk/Sessions/Details/334",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 21,
      OrgId: 1,
      EventDate: "1520899200000",
      Description:
        "We live in a world where our tools, frameworks and libraries are often built for us. Especially in .NET, we rely on http://ASP.NET MVC, DI Containers, Unit testing tools and IDEs and all of these things sell themselves on hiding complexity and being \u0027hard problems\u0027.",
      Title: "How Stuff Works - Metaprogramming in C# 101 ; David Whitney",
      Url: "https://www.meetup.com/DotNetNorth/events/247197948/",
    },
  },
  {
    Organiser: {
      Id: 6,
      Name: "Redis Manchester",
      LogoUrl: "RedisLogo",
      Website: "https://www.meetup.com/Redis-Manchester/",
      Description: null,
    },
    Event: {
      Id: 22,
      OrgId: 6,
      EventDate: "1521676800000",
      Description:
        "Jonnie Glacy: How Redis powers BBC Online’s biggest pages. Redis is so much more than a cache. The BBC uses Redis to orchestrate the creation and dynamic update of web pages that are accessed by millions of users every day. This talk looks at how Redis works in production at scale, as a cache, queue and data store.,",
      Title: "Streams, Redisson and how the BBC uses Redis!",
      Url: "https://www.meetup.com/Redis-Manchester/events/248226893/",
    },
  },
  {
    Organiser: {
      Id: 7,
      Name: "Microservices North",
      LogoUrl: "MicroserviceLogo",
      Website: "https://www.meetup.com/Microservices-North/",
      Description: null,
    },
    Event: {
      Id: 23,
      OrgId: 7,
      EventDate: "1523401200000",
      Description:
        "We will have talks on organizational patterns for microservices development and the story of how a global travel booking monolith was tranformed into microservices.",
      Title: "Microservices People Patterns",
      Url: "https://www.meetup.com/Microservices-North/events/248866034/?oc=evam",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 24,
      OrgId: 1,
      EventDate: "1528758000000",
      Description:
        "Blazor is an experimental web framework from the ASP.Net team that uses WebAssembly to allow you to write C# code that runs in the browser. In this talk, Rick will take a first look at the technology, it\u0027s background and show how to build a simple app.",
      Title: "First look at Blazor",
      Url: "https://www.meetup.com/DotNetNorth/events/251417644/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 25,
      OrgId: 1,
      EventDate: "1536620400000",
      Description:
        "This month John Nicholas will take a look at the evolution of architectural patterns, past, present and future - from DDD through CQRS to the recent emergence of actor systems. And as a bonus he\u0027ll blow up some assumptions along the way.",
      Title: "DDD - Desperately Destroying Death stars",
      Url: "https://www.meetup.com/DotNetNorth/events/251477671/",
    },
  },
  {
    Organiser: {
      Id: 8,
      Name: "Manchester Node Workshop",
      LogoUrl: "NodeJsLogo",
      Website: "https://www.meetup.com/manchester-node-workshop/",
      Description: null,
    },
    Event: {
      Id: 25,
      OrgId: 8,
      EventDate: "1545091200000",
      Description:
        "Sails.js is a web framework that makes it easy to build custom, enterprise-grade Node.js apps. It is designed to resemble the MVC architecture from frameworks like Ruby on Rails, but with support for the more modern, data-oriented style of web app \u0026 API development.",
      Title: "Building and testing Web Applications from scratch",
      Url: "https://www.meetup.com/manchester-node-workshop/events/256630123/",
    },
  },
  {
    Organiser: {
      Name: "Manchester Patterns Group",
      LogoUrl: "MPGLogo",
      Website: "https://www.meetup.com/Manchester-Patterns-Group",
      Description: null,
    },
    Event: {
      EventDate: "1549411200000",
      Description:
        "This session will be on Strategy 💡. We'll review the pattern as a group and then open the discussion with the following questions: What happens when a system has an explosion of Strategy objects? Is there some way to better manage these strategies?",
      Title: "Let's talk about... Strategy!",
      Url: "https://www.meetup.com/Manchester-Patterns-Group/events/258494932/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      EventDate: "1549929600000",
      Description:
        "In this talk Jon will introduce the basics of serverless development and explain how to get up and running with the Serverless Framework on Amazon Web Services. He will reveal how his team succeeded in deploying a critical service into production in less than two weeks and share some of the hard-learned lessons gleaned on the way.",
      Title: "Building .net core apps using AWS and the Serverless Framework",
      Url: "https://www.meetup.com/DotNetNorth/events/258556671/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      EventDate: "1549929600001",
      Description:
        "In this talk Jon will introduce the basics of serverless development and explain how to get up and running with the Serverless Framework on Amazon Web Services. He will reveal how his team succeeded in deploying a critical service into production in less than two weeks and share some of the hard-learned lessons gleaned on the way.",
      Title: "Building .net core apps using AWS and the Serverless Framework",
      Url: "https://www.meetup.com/DotNetNorth/events/258556671/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      EventDate: "1551744000000",
      Description:
        "Introduction to Event Sourcing - Steve Hocking, Reflecting on Reflection in an open source library - Steven Atkinson, Leeroy-Driven Development - John Nicholas",
      Title: "Lightning talks",
      Url: "https://www.meetup.com/DotNetNorth/events/258556671/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dev Ops Manchester",
      LogoUrl: "DOMLogo",
      Website: "https://www.meetup.com/DevOps-Manchester/",
      Description: null,
    },
    Event: {
      EventDate: "1554249600000",
      Description:
        "A group of us from a range of North West businesses have got together to restart the DevOps Manchester meetups and this is our first meetup as part of that team. On the night we have two talks followed by three lightning talks.",
      Title: "DevOps Manchester 15 - The Return",
      Url: "https://www.meetup.com/DevOps-Manchester/events/258760358/",
    },
  },
  {
    Organiser: {
      Id: 8,
      Name: "Manchester Node Workshop",
      LogoUrl: "NodeJsLogo",
      Website: "https://www.meetup.com/manchester-node-workshop/",
      Description: null,
    },
    Event: {
      Id: 25,
      OrgId: 8,
      EventDate: "1554336000000",
      Description:
        "We are back this April and looking forward to these tech topics: 1. Getting started with TypeScript & 2. Server side prerendering",
      Title: "Manchester Node.js meetup",
      Url: "https://www.meetup.com/manchester-node-workshop/events/256630123/",
    },
  },
  {
    Organiser: {
      Id: 8,
      Name: "Manchester Web Meetup",
      LogoUrl: "MWMLogo",
      Website: "https://www.meetup.com/Manchester-Web-Meetup/",
      Description: null,
    },
    Event: {
      Id: 25,
      OrgId: 8,
      EventDate: "1555459200000",
      Description:
        "Our great speakers topics are : A Talk of Ice & Fire, Creating Your Own Static Website Generator & BBC: Creating a data-driven and atomically designed website",
      Title: "Manchester Web Meetup #7",
      Url: "https://www.meetup.com/Manchester-Web-Meetup/events/260446380/",
    },
  },
  {
    Organiser: {
      Id: 1,
      Name: "Dot Net North",
      LogoUrl: "DotNetNorthLogo",
      Website: "https://www.meetup.com/DotNetNorth/",
      Description: null,
    },
    Event: {
      Id: 6,
      OrgId: 1,
      EventDate: "1560816000000",
      Description:
        "Message queuing is becoming an essential part of modern architectures and essential for asynchronous architectures and microservices. In this session will be described the benefits of messaging systems, the software solutions that are available and typical messaging architectures.",
      Title:
        "Messaging - RabbitMQ, Azure (Service Bus), Docker and Azure Functions",
      Url: "https://www.meetup.com/DotNetNorth/events/262193329/",
    },
  },
  {
    Organiser: {
      Name: "Software Crafters North",
      LogoUrl: "SCNLogo",
      Website: "https://www.meetup.com/Software-Crafters-North/",
      Description: null,
    },
    Event: {
      Id: 6,
      OrgId: 1,
      EventDate: "1571529600000",
      Description:
        "Following on from our previous sessions on TDD will will be delving into some of the processes outlined in the book 'Growing Object-Oriented Software, Guided By Tests' by Steve Freeman and Nat Price. Looking at how to approach developing new features test first.",
      Title: "Growing Object-Oriented Software (GOOS)",
      Url: "https://www.meetup.com/DotNetNorth/events/262193329/",
    },
  },
];
