import * as React from "react";

import { startSpaceInvaders, resizeGame } from "./js/spaceInvaders.js";
import { Box, Image } from "@chakra-ui/react";

interface IProjectContainerProps {
  name: string;
  summary: string;
  description?: string;
  frontEndDescription?: string;
  backEndDescription?: string;
  websitePreviewImage?: string;
  logoImageName: string;
  urlLink?: string;
  displayGame?: boolean;
}

interface IProjectContainerState {
  isExpanded: boolean;
  currentWidth: number;
}

export default class ProjectContainer extends React.Component<
  IProjectContainerProps,
  IProjectContainerState
> {
  constructor(props: IProjectContainerProps) {
    super(props);
    this.state = { isExpanded: false, currentWidth: 0 };
    this.handleIconClick = this.handleIconClick.bind(this);
  }

  renderHeaderBar() {
    var buttonComponent = <Box></Box>;

    return (
      <Box className="pc-HeaderContainer" onClick={this.handleIconClick}>
        <Box className="pc-Container_emoticon">
          <Image
            src={`Images/${this.props.logoImageName}`}
            alt="ProjectLogo"
            width={"500px"}
            className={"employment-image"}
          />
        </Box>

        <Box className="pc-Container_header">{this.props.name}</Box>

        <Box className="pc-Container_icon">{buttonComponent}</Box>
      </Box>
    );
  }

  getWidthForGame(): number {
    var currentWidth = this.state.currentWidth;

    if (currentWidth <= 450) {
      return 300;
    }
    if (currentWidth <= 650) {
      return 400;
    }
    return 600;
  }

  getHeightForGame(width: number) {
    switch (width) {
      case 300:
        return 200;
      case 400:
        return 350;
      case 600:
        return 450;
    }
  }

  handleIconClick() {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));

    if (this.props.displayGame && !this.state.isExpanded) {
      let gameWidth = this.getWidthForGame();
      let gameHeight = this.getHeightForGame(gameWidth);
      resizeGame(gameWidth, gameHeight);
      startSpaceInvaders();
    }
  }

  renderSummary(): JSX.Element {
    if (this.props.summary !== "") {
      return <Box className="pc-Container_paragraph">{this.props.summary}</Box>;
    }
    return <></>;
  }

  renderDescription(): JSX.Element {
    if (this.props.description !== "") {
      return (
        <Box className="pc-Container_paragraph">{this.props.description}</Box>
      );
    }
    return <></>;
  }

  renderPreviewImage(): JSX.Element {
    if (!this.props.websitePreviewImage) {
      return <></>;
    }
    return (
      <Box className="pc-Container_PreviewImage">
        <Image
          src={`Images/${this.props.websitePreviewImage}`}
          alt="Website Image Preview"
        />
      </Box>
    );
  }

  renderFrontend(): JSX.Element {
    if (!this.props.frontEndDescription) {
      return <></>;
    }
    return (
      <Box className="">
        <Box className="pc-Container_subHeading">Frontend</Box>
        <Box className="pc-Container_paragraph">
          {this.props.frontEndDescription}
        </Box>
      </Box>
    );
  }

  renderTechSection(): JSX.Element {
    return (
      <Box className="pc-Container_techSection">
        {this.renderFrontend()}
        {this.renderBackend()}
      </Box>
    );
  }

  renderBackend(): JSX.Element {
    if (!this.props.backEndDescription) {
      return <></>;
    }
    return (
      <Box className="">
        <Box className="pc-Container_subHeading">Backend</Box>
        <Box className="pc-Container_paragraph">
          {this.props.backEndDescription}
        </Box>
      </Box>
    );
  }

  renderLink(): JSX.Element {
    if (this.props.urlLink != null) {
      return (
        <Box className="pc-Container_link">
          <a href="https://chefster.co.uk">{this.props.urlLink}</a>
        </Box>
      );
    }
    return <></>;
  }

  componentDidMount() {
    this.setState({ currentWidth: window.innerWidth });
  }

  renderGame(): JSX.Element {
    if (!this.props.displayGame) {
      return <></>;
    }
    return (
      <Box className="pc-Container_game">
        <canvas className="pc-Container_gameCanvas"></canvas>
      </Box>
    );
  }

  renderContent(): JSX.Element {
    return (
      <>
        {this.renderLink()}
        {this.renderSummary()}
        {this.renderDescription()}
        {this.renderTechSection()}
        {this.renderPreviewImage()}
        {this.renderGame()}
      </>
    );
  }

  render() {
    var containerNormalClassName: string = "pc-Container col-lg-8 offset-lg-2";
    var containerClassName: string = this.state.isExpanded
      ? `${containerNormalClassName} pc-Container_expanded`
      : containerNormalClassName;

    return (
      <Box className={containerClassName}>
        {this.renderHeaderBar()}
        {this.renderContent()}
      </Box>
    );
  }
}
