import * as React from "react";
import { Box, Link, VStack, Text, Stack } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

interface IEmploymentProps {
  name: string;
  url: string;
  title: string;
  period: string;
  logoUrl: string;
  description: string[];
  odd: boolean;
  imageName: string;
  logoHasBorder?: boolean;
}

export default class EmploymentCard extends React.Component<
  IEmploymentProps,
  any
> {
  render() {
    return (
      <Link href={this.props.url} isExternal>
        <Box
          className="neo-box neo-box-paragraph-padding push hover"
          borderRadius={0}
          maxW={"750px"}
        >
          <VStack spacing={10}>
            <Stack
              justifyContent={"space-around"}
              alignItems={"center"}
              width={"100%"}
              flexDirection={{ base: "column", md: "row" }}
              spacing={10}
            >
              <Image
                src={`Images/webp/${this.props.logoUrl}.webp`}
                alt="employee Logo"
                maxW={"250px"}
                className={"employment-image"}
              />
              <Box
                className="neo-box fuzzy neo-box-paragraph-padding pull"
                borderRadius={0}
              >
                <VStack>
                  <Text fontSize="lg" fontWeight={600}>
                    {this.props.title}
                  </Text>
                  <Text fontSize="md"> {this.props.period}</Text>
                </VStack>
              </Box>
            </Stack>

            {this.props.description.map((desc, i) => {
              return (
                <Text
                  className=""
                  margin={"25px"}
                  fontSize={"s"}
                  key={`${this.props.name}_description_${i}`}
                >
                  {desc}
                </Text>
              );
            })}

            <Image
              src={`Images/jpg/${this.props.imageName}.jpg`}
              alt="image of employee website"
              width={"500px"}
              className={"employment-image"}
            />
          </VStack>
        </Box>
      </Link>
    );
  }
}
