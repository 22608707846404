import "./aboutMe.css";
import * as React from "react";
import me from "./assets/me.webp";
import { Box, Image, Stack, Text } from "@chakra-ui/react";

const AboutMeComponent: React.FC = () => {
  return (
    <Box id="aboutMe" className="ap-site-slice">
      <Box className="site-Subheader">About me</Box>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={"100px"}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          className="neo-box push am-text-box"
          padding={{ base: "30px", xl: "100px" }}
        >
          <Text fontSize={"md"}>
            I’m a people-first developer who’s passionate about software
            development and the surrounding ecosystems. My general preference is
            working in the backend but I like to think of myself as a T-shaped
            developer ready to take on any challenge.
          </Text>
        </Box>

        <Box style={{ margin: 0 }} padding={{ base: "30px", xl: "100px" }}>
          <Image src={me} className={"am-Selfie"} />
        </Box>

        <Box
          className="neo-box  push am-text-box"
          padding={{ base: "30px", xl: "100px" }}
        >
          <Text fontSize={"md"}>
            I’ll actively try to improve products and processes where
            appropriate. I’ve attended a lot of tech talks searching for better
            approaches to development and always have an ongoing personal
            project to practise something new or expand my existing knowledge.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default AboutMeComponent;
