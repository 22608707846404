import * as React from "react";
import HeaderImage from "../headerImage/HeaderImage";
import AboutMe from "../aboutMe/AboutMe";
import Skills from "../skills/Skills";
import Employment from "../employment/Employment";
import Education from "../education/Education";
import Meetup from "../meetups/Meetup";
import FooterComponent from "../footer/Footer";
import Projects from "../projects/Projects";
import { Box } from "@chakra-ui/react";
import NavigationComponent from "../navigation/NavigationComponent";

const FullPageComponent: React.FC = () => {
  return (
    <Box>
      <Box>
        <HeaderImage />
      </Box>
      <Box>
        <NavigationComponent />
      </Box>

      <Box>
        <Box>
          <AboutMe />
        </Box>
        <Box>
          <Skills />
        </Box>
        <Box>
          <Employment />
        </Box>
        <Box>
          <Education />
        </Box>
        <Box>
          <Projects />
        </Box>
        <Box>
          <Meetup />
        </Box>
      </Box>
      <Box>
        <FooterComponent />
      </Box>
    </Box>
  );
};

export default FullPageComponent;
