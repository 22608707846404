import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { tabsAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    _selected: {
      color: "pink",
    },
  },
  tablist: {
    flexWrap: "wrap",
  },
  tabpanel: {
    fontFamily: "mono", // change the font family
    width: "100%",
  },
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle });
