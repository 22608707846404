import { useEffect, useState } from "react";
import "./testimonial.css";
import { Box, Text, VStack } from "@chakra-ui/react";

interface Testimonial {
  text: string;
  name: string;
  role: string;
  isActive: boolean;
  timeout?: number;
}

const testimonialData: Testimonial[] = [
  {
    text: "Aiden has an instinctive ability to look out for his teammates and do whatever he can to support them, whether that involves having challenging conversations with SLT or getting hands-on with support requests. He values the team above all else, ensuring everyone is onboard with the direction of travel and that all of their voices are heard. Leadership comes naturally to Aiden and he stands by his principals, something we all trust and respect him for.",
    name: "David Smith",
    role: "Business Analyst",
    isActive: false,
    timeout: 15000,
  },
  {
    text: "Aiden's technical ability is continually complimented by his willingness to learn away from the workplace in his own time.",
    name: "Lee Williams",
    role: "Tech lead",
    isActive: true,
  },
  {
    text: "Aiden was an important part of my team and set an example for junior developers to follow by using SOLID principles and design patterns.",
    name: "Christian Coda",
    role: "Tech lead",
    isActive: false,
  },
  {
    text: "I have always found Aiden very helpful pleasant and friendly, even when being interrupted from what he was doing.",
    name: "Anonymous",
    role: "Team Member",
    isActive: false,
  },
  {
    text: "Aiden always fights for things to be done in the best/most correct way technically, and won't just do things because someone's told him to.",
    name: "Anonymous",
    role: "Team Member",
    isActive: false,
  },
  {
    text: "I am always impressed with the way Aiden remains positive even in the most negative of environments.",
    name: "Anonymous",
    role: "Team Member",
    isActive: false,
  },
  {
    text: "Without Aiden, I wouldn't be half the developer I am today, he's a code wizard.",
    name: "Warren Pitterson",
    role: "Trainee",
    isActive: false,
  },
];

export const TestimonialComponent: React.FC = () => {
  const [testimonials, setTestimonials] = useState(testimonialData);
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  const [isTransitioning, setIsTransitionning] = useState(false);

  useEffect(() => {
    const timeout = testimonials[activeTestimonialIndex].timeout
      ? testimonials[activeTestimonialIndex].timeout
      : 8000;
    setTimeout(() => {
      setIsTransitionning(true);
      setTimeout(() => {
        let newIndex = activeTestimonialIndex + 1;
        if (newIndex >= testimonials.length) {
          newIndex = 0;
        }
        setActiveTestimonialIndex(newIndex);
        setIsTransitionning(false);
      }, 2000);
    }, timeout);
  }, [
    activeTestimonialIndex,
    setTestimonials,
    testimonials,
    testimonials.length,
  ]);

  if (testimonials === undefined) {
    return <div>loading</div>;
  } else {
    return (
      <Box className="tm-Slice" marginTop={"100px"}>
        <Box
          className="neo-box pull neo-box-paragraph-padding fuzzy"
          marginLeft={0}
          marginRight={0}
          borderRadius={0}
          style={{ borderRadius: 0 }}
          height={"350px"}
        >
          <VStack
            opacity={isTransitioning ? "0" : "1"}
            className="testimonial-container"
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <Text
              maxW={"900px"}
              fontSize={{ base: "x-small", medium: "large" }}
              lineHeight={{ base: "10px", medium: "15px" }}
            >
              <Text
                fontWeight={600}
                fontSize="large"
                as="span"
                lineHeight={{ base: "10px", medium: "15px" }}
              >
                “
              </Text>
              {testimonials[activeTestimonialIndex].text}
              <Text
                fontWeight={600}
                fontSize="large"
                as="span"
                lineHeight={{ base: "10px", medium: "15px" }}
              >
                ”
              </Text>
            </Text>
            <Text>
              -{" "}
              <Text fontWeight={600} as="span">
                {testimonials[activeTestimonialIndex].name}
              </Text>
              , {testimonials[activeTestimonialIndex].role}
            </Text>
          </VStack>
        </Box>
      </Box>
    );
  }
};

export default TestimonialComponent;
