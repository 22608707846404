import { Box, HStack, Text, Image, VStack } from "@chakra-ui/react";
import * as React from "react";

interface IMeetupCardProps {
  organiserName: String;
  organiserUrl: string;
  organiserLogoUrl: string;
  title: String;
  description: String;
}
export default class MeetupCard extends React.Component<IMeetupCardProps, any> {
  render() {
    return (
      <VStack
        className="neo-box push neo-box-paragraph-padding"
        minW={"400px"}
        minH={"400px"}
        justifyContent={"space-evenly"}
        borderRadius={0}
      >
        <HStack height={"100px"} justifyContent={"space-evenly"}>
          <Image
            src={`Images/webp/${this.props.organiserLogoUrl}.webp`}
            alt="employee Logo"
            width={"30%"}
          />
          <Box>
            <Text>{this.props.organiserName}</Text>
          </Box>
        </HStack>
        <Text fontWeight={"800"}>{this.props.title}</Text>

        <Text fontSize={"xs"} minHeight={"100px"}>
          {this.props.description}
        </Text>
      </VStack>
    );
  }
}
