import { Box, Center, Spacer, Text, VStack } from "@chakra-ui/react";
import * as React from "react";

class Education extends React.Component {
  render() {
    return (
      <Box id="education" className="ap-site-slice">
        <Box className="site-Subheader">Education</Box>
        <Center marginTop={"100px"}>
          <Box
            className="neo-box push fuzzy neo-box-paragraph-padding"
            maxW={"800px"}
            borderRadius={0}
            padding={"60px"}
            style={{ padding: "60px" }}
          >
            <VStack>
              <Text>
                I graduated from Manchester Metropolitan University in 2015 with
                a first class honors in Software Engineering after completing a
                placement year in 2013-2014 with
                <a href="http://www.topcashback.co.uk">TopCashback</a> who went
                on to employ me after graduating.
              </Text>
              <Spacer />
              <Text>
                For my dissertation project I worked within a team of 5 to
                develop a 'Smart Home' system which would control simple house
                hold objects such as lighting, heating and locks based on
                information such as temperature, geolocation or simple on-off
                interfaces within any browser.
              </Text>
            </VStack>
          </Box>
        </Center>
      </Box>
    );
  }
}
export default Education;
