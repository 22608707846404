import "./App.css";
import "./AppNew.css";
import FullPageComponent from "./components/fullpage/FullPage";
import { ChakraProvider } from "@chakra-ui/react";
import GetTheme from "./theme/theme";

function App() {
  const theme = GetTheme();

  return (
    <ChakraProvider theme={theme}>
      <FullPageComponent />;
    </ChakraProvider>
  );
}

export default App;
