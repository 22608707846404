import * as React from "react";
import "./skills.css";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Tab,
  TabList,
  Text,
  TabPanel,
  TabPanels,
  Tabs,
  Progress,
} from "@chakra-ui/react";

interface Skill {
  name: string;
  description?: string;
  skillAmount: number;
  frequencyAmount: number;
}

interface Skills {
  designAndPlanning: Skill[];
  building: Skill[];
  languages: Skill[];
  monitoringAndObservability: Skill[];
  anythingElse: Skill[];
}

const skills: Skills = {
  designAndPlanning: [
    {
      name: "JIRA",
      description:
        "I've used JIRA in every job i've worked at so far great for tickets/epics and planning / tracking.",
      skillAmount: 70,
      frequencyAmount: 100,
    },
    {
      name: "Miro",
      description:
        "A great tool for white boarding and collaborating amongst teams, don't know how working from home would function without this.",
      skillAmount: 80,
      frequencyAmount: 100,
    },
    {
      name: "Figma",
      description: "Used to design and wireframe websites",
      skillAmount: 30,
      frequencyAmount: 20,
    },
  ],
  languages: [
    {
      name: "C#",
      description:
        "I used C# personally and professionally since University through to cinch. This was the first professional company where I put C# on a shelf for Typescript. I would say I know it well, but I might require a small timeframe to brush off some of the more complicated nuiances before picking this up professionally again.",
      skillAmount: 90,
      frequencyAmount: 10,
    },
    {
      name: "Typescript",
      description:
        "Typescript is what I've used personally and professionally for the last 4 years, I find it's a fantastically powerful language that solves the down sides of C# (being type script / heavy on ide) and javascript (No types at all!). Type infered is the future I think.",
      skillAmount: 85,
      frequencyAmount: 100,
    },
    {
      name: "YAML",
      description:
        "I can't express a grand amount of interest or knowledge in YAML, I've strictly only used it for pipelines, not even sure if it's used for much else other than that.",
      skillAmount: 40,
      frequencyAmount: 60,
    },
    {
      name: "Javascript",
      description:
        "I've used javascript on and off since i began development, to do more complex things on a website through to creating a server for a university application. I generally try to avoid it where I can, but the knowledge is there to get the job done if needs be. I wouldn't classify myself as an expert in it.",
      skillAmount: 50,
      frequencyAmount: 30,
    },
  ],
  building: [
    {
      name: "React",
      description:
        "I've used React since bet365 personally, and professionally in cinch. I think it's a great front end with brilliant capabilities, I think it'll be a hard sell to pull me away from it now.",
      skillAmount: 75,
      frequencyAmount: 100,
    },
    { name: ".NET Core", skillAmount: 55, frequencyAmount: 35 },
    { name: "HTML", skillAmount: 75, frequencyAmount: 60 },
    { name: "CSS", skillAmount: 70, frequencyAmount: 65 },
    { name: "Node", skillAmount: 60, frequencyAmount: 100 },
    { name: "Bootstrap", skillAmount: 70, frequencyAmount: 25 },
    { name: "Sass", skillAmount: 65, frequencyAmount: 15 },
    { name: "Visual Studio", skillAmount: 85, frequencyAmount: 25 },
    { name: "VSCode", skillAmount: 70, frequencyAmount: 100 },
  ],
  monitoringAndObservability: [
    { name: "Datadog", skillAmount: 75, frequencyAmount: 80 },
    { name: "Cloudwatch", skillAmount: 85, frequencyAmount: 95 },
    { name: "Metabase", skillAmount: 65, frequencyAmount: 35 },
    { name: "PowerBI", skillAmount: 55, frequencyAmount: 45 },
  ],
  anythingElse: [
    { name: "Gitlab", skillAmount: 75, frequencyAmount: 45 },

    { name: "Github", skillAmount: 85, frequencyAmount: 95 },
    { name: "AWS", skillAmount: 80, frequencyAmount: 95 },
    { name: "Teamcity", skillAmount: 45, frequencyAmount: 35 },
  ],
};

const generateAccordianSegments = (skills: Skill[]): JSX.Element[] => {
  return skills.map((skill) => {
    return (
      <AccordionItem width={"100%"} key={`skillName_${skill.name}`}>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Text fontWeight={800}>{skill.name}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} width={"100%"}>
          <Box width={"100%"}>
            Strength
            <Progress hasStripe size="xs" value={skill.skillAmount} />
            Frequency of use
            <Progress hasStripe size="xs" value={skill.frequencyAmount} />
          </Box>
        </AccordionPanel>
      </AccordionItem>
    );
  });
};

const skillsComponent: React.FC = () => {
  return (
    <Box className="ap-site-slice" id="skills">
      <Box className="site-Subheader">My Skills</Box>

      <Center>
        <Tabs
          marginTop={"100px"}
          justifyItems={"center"}
          alignItems={"center"}
          className="neo-box neo-box-paragraph-padding push"
          maxW={"1000px"}
        >
          <TabList>
            <Tab>Languages</Tab>
            <Tab>Building</Tab>
            <Tab>Monitoring & Observability</Tab>
            <Tab>Design & Planning</Tab>
            <Tab>Anything Else</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <HStack justifyContent={"center"}>
                <Accordion defaultIndex={[0]} width={"100%"}>
                  {generateAccordianSegments(skills.languages)}
                </Accordion>
              </HStack>
            </TabPanel>
            <TabPanel>
              <HStack justifyContent={"center"}>
                <Accordion defaultIndex={[0]} width={"100%"}>
                  {generateAccordianSegments(skills.building)}
                </Accordion>
              </HStack>
            </TabPanel>
            <TabPanel>
              <HStack justifyContent={"center"}>
                <Accordion defaultIndex={[0]} width={"100%"}>
                  {generateAccordianSegments(skills.monitoringAndObservability)}
                </Accordion>
              </HStack>
            </TabPanel>
            <TabPanel>
              <HStack justifyContent={"center"}>
                <Accordion defaultIndex={[0]} width={"100%"}>
                  {generateAccordianSegments(skills.designAndPlanning)}
                </Accordion>
              </HStack>
            </TabPanel>
            <TabPanel>
              <HStack justifyContent={"center"}>
                <Accordion defaultIndex={[0]} width={"100%"}>
                  {generateAccordianSegments(skills.anythingElse)}
                </Accordion>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>
    </Box>
  );
};

export default skillsComponent;
