import * as React from "react";
import Testimonial from "../testimonials/Testimonial";
import EmploymentCard from "./EmploymentCard";
import "./employment.css";
import { Box, Stack } from "@chakra-ui/react";

export default class Employment extends React.Component {
  render() {
    return (
      <Box id="employment" className="ap-site-slice">
        <Box className="site-Subheader">Employment</Box>
        <Stack
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent={"space-evenly"}
          marginTop={"100px"}
        >
          <EmploymentCard
            key={"cinchTL"}
            name={"Cinch"}
            title={"Tech Lead"}
            url={"https://cinch.co.uk"}
            period={"January 2022 to Present"}
            logoUrl={"cinch"}
            description={[
              `Most things i did in thie role directly correlated to the squads health and well being, building up trust with my direct reports and making sure it's never betrayed.`,
              `Harbouring a safe space where all the squad can talk openly and freely is an important attitude I would like to replicate everywhere I go. That safe space and transparency helps the team grow quickly and efficiently.`,
              `Overseeing the delivery of things like migration plans, software development cycles, ensuring good practices are considered and carving out intatives, the responsibilities of this role were very diverse and required a good level of adaptability and logical thinking.`,
              `Some of my favourite memories in this roll will be watching senior devs champion an entire initative from planning through to delivery or having junior deliver information on our latest initative to a show a tell, I like to think these things are achieved easily when the group dynamic is such a happy space.`,
            ]}
            odd={false}
            imageName={"cinchImage"}
            logoHasBorder={false}
          />

          <EmploymentCard
            key={"cinchSoftwareDev"}
            name={"Cinch"}
            title={"Software Developer"}
            url={"https://cinch.co.uk"}
            period={"September 2020 to January 2022"}
            logoUrl={"cinch"}
            description={[
              `Everything is written in Typescript and hosted in AWS at cinch. With backends being span up via CDK or Serverless predominately using lambdas or tools in the AWS ecosystem`,

              `The front ends are React sitting on Next or Gatsby. Utilising Storybook for rendering individual components or pages and Storyblok as a CMS.`,

              `The company is held together via a shared event bus which is utilised in an event driven architcture. This was new to me upon arrival but something i was keen to get stuck into and learn.`,

              `The tools we use for observability and monitoring were Datadog & Cloudwatch. For the more analytical side of things we used a mixture of Metabase, PowerBI and Snowflake to report on our events and/or build product based dashboards.`,
            ]}
            odd={false}
            imageName={"cinchImage"}
            logoHasBorder={false}
          />
        </Stack>{" "}
        <Stack
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent={"space-evenly"}
          alignItems={"space-between"}
          marginTop={"100px"}
        >
          <EmploymentCard
            key={"bet365"}
            name={"Bet365"}
            title={"Full-stack Software Developer"}
            url={"https://Bet365.com"}
            period={"Feburary 2018 to September 2020"}
            logoUrl={"bet365"}
            description={[
              `Bet365 has taught me an incredible amount through it's website which boasts 500k database transactions a second in 18+ languages. 
                        The main priorites on the table are always performance, stability and scope. The company has been using a SoA/Microservice architecture for so long, 
                        there's a very high degree of multi service understanding required to perform in the roll.`,
            ]}
            odd={true}
            imageName={"BetImage"}
            logoHasBorder={true}
          />

          <EmploymentCard
            key={"tcb"}
            name={"TopCashback"}
            title={"Full-stack Software Developer"}
            url={"http://www.topcashback.co.uk"}
            period={"May 2013 to November 2017"}
            logoUrl={"TCBLogo"}
            description={[
              `This job taught me a lot of fundamentals in programming such as SOLID, TDD, Continuous Integration, Git and Microservice architecture 
                        and generally covered a lot of Microsoft technologies. During my time here I've worked on desktop applications, websites, back end API's 
                        and both SQL and No-SQL data stores.`,
            ]}
            odd={false}
            imageName={"TCBImage"}
            logoHasBorder={true}
          />
        </Stack>
        <Testimonial />
      </Box>
    );
  }
}
