import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { tabsTheme } from "./tabTheme";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

function GetTheme() {
  return extendTheme({
    config,
    components: {
      Tabs: tabsTheme,
    },
    colors: {
      custom: {
        light: {
          background: "#ebf5fc",
          accent: "salmon",
          neo: {
            lightborder: "rgba(255, 255, 255, 1)",
            darkborder: "rgba(0, 0, 0, 0.1)",
            hover: {
              lightborder: "rgba(255, 255, 255, 0.5)",
              darkborder: "rgba(0, 0, 0, 0.05)",
            },
          },
        },
        dark: {
          background: "#212529",
          accent: "salmon",
          neo: {
            lightborder: "#3b3b3b63",
            darkborder: "#0000008c",
            hover: {
              lightborder: "rgba(255, 255, 255, 0.5)",
              darkborder: "rgba(0, 0, 0, 0.05)",
            },
          },
        },
      },
    },
  });
}

// 3. extend the theme
export default GetTheme;
