import * as React from "react";
import "./headerImage.css";
import { Box, Container, Flex, Spacer } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

export default function HeaderImage() {
  const amountOfBlubs = 70;
  const heightOfHeader = 800;

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const createBlub = (id: number): JSX.Element => {
    const isPush = randomIntFromInterval(0, 10) >= 5 ? `pull` : `push`;
    const isFuzzy = randomIntFromInterval(0, 10) >= 5 ? `fuzzy` : ``;
    const isCircle = randomIntFromInterval(0, 10) >= 5 ? "400" : "0";
    const maxHeightOfObject = 200;
    const margin = -100;
    return (
      <Box
        className={`neo-box ${isPush} ${isFuzzy}`}
        position={"absolute"}
        key={id}
        top={randomIntFromInterval(
          0,
          heightOfHeader - (maxHeightOfObject * 2 + margin * 2)
        )}
        right={randomIntFromInterval(-margin, 2000)}
        borderRadius={`${isCircle}px`}
        padding={`${randomIntFromInterval(20, maxHeightOfObject)}px`}
        margin={margin}
        borderWidth={"1px"}
        borderColor={randomIntFromInterval(0, 10) >= 7 ? "salmon" : "none"}
        zIndex={1}
      />
    );
  };

  let blobs: JSX.Element[] = [];

  for (let i = 0; i < amountOfBlubs; i++) {
    blobs.push(createBlub(i));
  }

  return (
    <Flex
      height={`${heightOfHeader}px`}
      justifyContent={"center"}
      alignItems={"center"}
      className="ap-header"
      overflow={"hidden"}
    >
      <Spacer />
      {blobs.map((blob) => {
        return blob;
      })}
      <Container
        className="neo-box neo-box-paragraph-padding pull"
        marginRight="20%"
        zIndex={10}
        centerContent
      >
        <Text fontSize={"4xl"} fontWeight={800}>
          Aiden Poulton
        </Text>
        <Container
          className="neo-box neo-box-paragraph-padding push"
          width={"85%"}
          centerContent
        >
          <Text fontSize={"xl"}>Software Developer</Text>
        </Container>
      </Container>
      <Spacer />
    </Flex>
  );
}
