import * as React from "react";
import MeetupCard from "./MeetupCard";
import { meetups } from "./meetupData";
import "./meetup.css";
import { Box, Center, HStack, Text } from "@chakra-ui/react";

const meetupComponent: React.FC = () => {
  return (
    <Box className="ap-site-slice" id="meetups">
      <Box className="site-Subheader">Meetups</Box>
      <Center>
        <Box
          className="neo-box pull fuzzy neo-box-paragraph-padding"
          marginTop={"100px"}
          maxW={"800px"}
          borderRadius={0}
          padding={"60px"}
          style={{ padding: "60px" }}
        >
          <Text>
            In my spare time I try to attend tech based meetups & conferences, I
            find these are a really good way to wind down from work as well as
            getting insight into how various other companies and individuals
            have been utilising tech and what some of those benefits or pitfalls
            may be.
          </Text>
        </Box>
      </Center>
      <HStack overflow={"scroll"} marginTop={"100px"} spacing={20}>
        {meetups.reverse().map((meetup) => (
          <MeetupCard
            title={meetup.Event.Title}
            organiserName={meetup.Organiser.Name}
            organiserLogoUrl={meetup.Organiser.LogoUrl}
            organiserUrl={meetup.Organiser.LogoUrl}
            description={meetup.Event.Description}
            key={meetup.Event.EventDate}
          />
        ))}
      </HStack>
    </Box>
  );
};

export default meetupComponent;
