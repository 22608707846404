import * as React from "react";
import "./footer.css";

export const FooterComponent: React.FC = () => {
  return (
    <div className=" footer-slice">
      <div className="button-container">
        <a
          href="https://www.linkedin.com/in/aidenpoulton/"
          title="to my linkedin"
        >
          {/* <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" className="footerIcon" /> */}
        </a>

        <a href="https://gitlab.com/aidenpoulton" title="to my gitlab">
          {/* <FontAwesomeIcon icon={["fab", "gitlab"]} size="2x" className="footerIcon" /> */}
        </a>
      </div>
    </div>
  );
};

export default FooterComponent;
