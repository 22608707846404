/* eslint-disable */
let canvas;
let c;
//CONSTANT SETTINGS
let SCREEN_WIDTH = 600;
let SCREEN_HEIGHT = 450;

let ENEMY_MOVEMENT_TICKS = 15;
let BULLET_MOVEMENT_TICKS = 5;

let MAXIMUM_BULLET_COUNT = 8;
let ROW_COUNT_OF_ENEMIES = 3;
let COLUMN_COUNT_OF_ENEMIES = 10;

let ENEMY_FIRE_CHANCE = 6;

//VARIABLES
let gameRunning = false;
let screenState = 1; //1 welcome screen, //2 game playing, //3 finsihed/gameover

let player = {};

let enemies = [];
let enemiesXOffset = 0;
let enemiesYOffset = 0;
let enemiesGoingRight = true;
let enemyUpImage = true;
let imgEnemyUp = new Image();
imgEnemyUp.src = "Images/alien-up.svg";
let imgEnemyDown = new Image();
imgEnemyDown.src = "Images/alien-down.svg";

let barriers = [];

let bullets = [];
let score = 0;

// Sizing
//let sizing_playerWidth = getPercentageOfScreenWidth(7);
//let sizing_playerHeight = getPercentageOfScreenHeight(5);
let sizing_bulletHeight = getPercentageOfScreenHeight(2);
let sizing_bulletWidth = getPercentageOfScreenWidth(0.5);
let sizing_enemyMaximumOffset = getPercentageOfScreenWidth(1);
let sizing_enemyWidth = getPercentageOfScreenWidth(5);
let sizing_enemyHeight = getPercentageOfScreenHeight(5);
let sizing_enemyAxisY = sizing_enemyHeight + getPercentageOfScreenHeight(5);
let sizing_enemyAxisX = sizing_enemyWidth + getPercentageOfScreenHeight(3);
let sizing_enemyMovementPerRound = getPercentageOfScreenWidth(1.5);
let sizing_marginX = getPercentageOfScreenWidth(10);
let sizing_marginY = getPercentageOfScreenHeight(5);
let sizing_playerMovementAmount = getPercentageOfScreenHeight(2);
let sizing_barrierEdgeMarginX = getPercentageOfScreenWidth(7);
let sizing_barrierLength = getPercentageOfScreenWidth(11);
let sizing_barrierYFromBottom = getPercentageOfScreenHeight(70);
let sizing_fullScreenTextStateX = getPercentageOfScreenHeight(20);
let sizing_fullScreenTextStateY = getPercentageOfScreenWidth(20);
let sizing_bulletTravelDistance = getPercentageOfScreenHeight(0.5);
let sizing_enemyMovementRange = getPercentageOfScreenWidth(98);
let sizing_enemyYMovementPerRound = getPercentageOfScreenHeight(2);

let sizing_playerBaseWidth = getPercentageOfScreenWidth(7.5);
let sizing_playerBaseHeight = getPercentageOfScreenHeight(2);
let sizing_playerHeadWidth = getPercentageOfScreenWidth(1.5);
let sizing_playerHeadHeight = getPercentageOfScreenHeight(1.5);
let sizing_playerBarrelWidth = getPercentageOfScreenWidth(0.5);
let sizing_playerBarrelHeight = getPercentageOfScreenHeight(1);
let sizing_playerHeight =
  sizing_playerBarrelHeight + sizing_playerHeadHeight + sizing_playerBaseHeight;

// Tick Countdowns
let enemyMovementCountdownFame;
let bulletMovementCountdownFrame;

function getPercentageOfScreenWidth(number) {
  return (SCREEN_WIDTH / 100) * number;
}

function getPercentageOfScreenHeight(number) {
  return (SCREEN_HEIGHT / 100) * number;
}

function calculateSizes() {
  // Sizing
  //sizing_playerWidth = getPercentageOfScreenWidth(7);
  //sizing_playerHeight = getPercentageOfScreenHeight(5);
  sizing_bulletHeight = getPercentageOfScreenHeight(2);
  sizing_bulletWidth = getPercentageOfScreenWidth(0.5);
  sizing_enemyMaximumOffset = getPercentageOfScreenWidth(1);
  sizing_enemyWidth = getPercentageOfScreenWidth(5);
  sizing_enemyHeight = getPercentageOfScreenHeight(5);
  sizing_enemyAxisY = sizing_enemyHeight + getPercentageOfScreenHeight(5);
  sizing_enemyAxisX = sizing_enemyWidth + getPercentageOfScreenHeight(3);
  sizing_enemyMovementPerRound = getPercentageOfScreenWidth(1.5);
  sizing_marginX = getPercentageOfScreenWidth(10);
  sizing_marginY = getPercentageOfScreenHeight(5);
  sizing_playerMovementAmount = getPercentageOfScreenHeight(2);
  sizing_barrierEdgeMarginX = getPercentageOfScreenWidth(7);
  sizing_barrierLength = getPercentageOfScreenWidth(11);
  sizing_barrierYFromBottom = getPercentageOfScreenHeight(70);
  sizing_fullScreenTextStateX = getPercentageOfScreenHeight(20);
  sizing_fullScreenTextStateY = getPercentageOfScreenWidth(20);
  sizing_bulletTravelDistance = getPercentageOfScreenHeight(0.5);
  sizing_enemyMovementRange = getPercentageOfScreenWidth(98);
  sizing_enemyYMovementPerRound = getPercentageOfScreenHeight(2);

  sizing_playerBaseWidth = getPercentageOfScreenWidth(7.5);
  sizing_playerBaseHeight = getPercentageOfScreenHeight(2);
  sizing_playerHeadWidth = getPercentageOfScreenWidth(1.5);
  sizing_playerHeadHeight = getPercentageOfScreenHeight(1.5);
  sizing_playerBarrelWidth = getPercentageOfScreenWidth(0.5);
  sizing_playerBarrelHeight = getPercentageOfScreenHeight(1);
  sizing_playerHeight =
    sizing_playerBarrelHeight +
    sizing_playerHeadHeight +
    sizing_playerBaseHeight;
}

function setUpGame() {
  reset();
  setUpPlayers();
  setUpEnemies();
  setUpBarriers();
}

function reset() {
  player = [];

  enemies = [];
  enemiesXOffset = 0;
  enemiesYOffset = 0;
  enemiesGoingRight = true;
  enemyUpImage = true;

  barriers = [];

  bullets = [];
  score = 0;
}

function setUpPlayers() {
  // CREATE PLAYER
  player = {
    x: SCREEN_WIDTH / 2 - sizing_playerBaseWidth / 2,
    y: SCREEN_HEIGHT - sizing_playerBaseHeight,
    parts: [],
    draw() {
      if (this.x <= 0) {
        this.x = 0;
      } else if (this.x >= SCREEN_WIDTH - sizing_playerBaseWidth) {
        this.x = SCREEN_WIDTH - sizing_playerBaseWidth;
      }

      this.parts.forEach((part) => {
        this.drawPart(
          part.calculateX(this.x),
          part.calculateY(this.y),
          part.width,
          part.height
        );
      });
    },
    drawPart(x, y, width, height) {
      c.fillStyle = "green";
      c.strokeStyle = "green";
      c.beginPath();
      c.fillRect(x, y, width, height);
      c.stroke();
    },
  };

  player.parts.push({
    name: "base",
    width: sizing_playerBaseWidth,
    height: sizing_playerBaseHeight,
    calculateX(x) {
      return x;
    },
    calculateY(y) {
      return y;
    },
  });

  player.parts.push({
    name: "head",
    width: sizing_playerHeadWidth,
    height: sizing_playerHeadHeight,
    calculateX(x) {
      return x + (sizing_playerBaseWidth / 100) * 40;
    },
    calculateY(y) {
      return y - sizing_playerBaseHeight;
    },
  });

  player.parts.push({
    name: "barrel",
    width: sizing_playerBarrelWidth,
    height: sizing_playerBarrelHeight,
    calculateX(x) {
      let returnobj =
        x +
        (sizing_playerBaseWidth / 100) * 40 +
        (sizing_playerHeadWidth / 100) * 33.3;
      return returnobj;
    },
    calculateY(y) {
      return y - sizing_playerBaseHeight - sizing_playerHeadHeight;
    },
  });
}

function setUpEnemies() {
  for (let rowIndex = 0; rowIndex < ROW_COUNT_OF_ENEMIES; rowIndex++) {
    for (
      let columnIndex = 0;
      columnIndex < COLUMN_COUNT_OF_ENEMIES;
      columnIndex++
    ) {
      let enemy = {
        width: sizing_enemyWidth,
        height: sizing_enemyHeight,
        x: sizing_marginX + sizing_enemyAxisX * columnIndex,
        y: sizing_marginY + rowIndex * sizing_enemyAxisY,
        status: 0,
        draw: function (enemyImage) {
          c.drawImage(enemyImage, this.x, this.y, this.width, this.height);
        },
      };
      enemies.push(enemy);
    }
  }
}

function setUpBarriers() {
  let lengthOfBarriers = sizing_barrierLength / sizing_bulletWidth;
  let betweenBarriers = 2 * sizing_barrierEdgeMarginX;

  for (let barrierIndex = 0; barrierIndex < 4; barrierIndex++) {
    for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
      for (let columnIndex = 0; columnIndex < lengthOfBarriers; columnIndex++) {
        let barrier = {
          width: sizing_bulletWidth,
          height: sizing_bulletHeight,
          x:
            sizing_barrierEdgeMarginX +
            columnIndex * sizing_bulletWidth +
            sizing_barrierLength * barrierIndex +
            betweenBarriers * barrierIndex,
          y: sizing_barrierYFromBottom + rowIndex * sizing_bulletHeight,
          status: 0,
          draw: function () {
            c.fillStyle = "green";
            c.strokeStyle = "green";
            c.beginPath();
            c.fillRect(this.x, this.y, sizing_bulletWidth, sizing_bulletHeight);
            c.stroke();
          },
        };
        barriers.push(barrier);
      }
    }
  }
}

//ARROW KEYS
let inputKeys = {
  leftArrow: 37,
  rightArrow: 39,
  f: 70,
  c: 67,
};

// eslint-disable-next-line no-restricted-globals
addEventListener("keydown", function (event) {
  if (inputKeys["leftArrow"] == event.keyCode) {
    player.x += -sizing_playerMovementAmount;
  } else if (inputKeys["rightArrow"] == event.keyCode) {
    player.x += sizing_playerMovementAmount;
  } else if (inputKeys["f"] == event.keyCode) {
    fireBullet(
      true,
      sizing_playerBaseWidth,
      player.x,
      player.y - sizing_playerHeight,
      "white"
    );
  } else if (
    inputKeys["c"] == event.keyCode &&
    (screenState == 1 || screenState == 3)
  ) {
    setUpGame();
    gameRunning = true;
    if ((screenState = 1)) {
      screenState = 2;
    } else {
      screenState = 1;
    }
  }
  return false;
});

function enemyAnimationTick() {
  if (enemyMovementCountdownFame > 0) {
    enemyMovementCountdownFame--;
  } else {
    enemyUpImage = !enemyUpImage;
    enemyMovementCountdownFame = enemies.length;
    let didDirectionShift = false;

    if (enemiesGoingRight) {
      let highestXValue = 0;
      enemies.forEach((enemy) => {
        if (enemy.x > highestXValue) {
          highestXValue = enemy.x;
        }
      });

      if (
        highestXValue + sizing_enemyMovementPerRound + sizing_enemyWidth >=
        sizing_enemyMovementRange
      ) {
        enemiesGoingRight = false;
        didDirectionShift = true;
      }
    } else {
      let lowestXValue = SCREEN_WIDTH;

      enemies.forEach((enemy) => {
        if (enemy.x < lowestXValue) {
          lowestXValue = enemy.x;
        }
      });

      if (
        lowestXValue - sizing_enemyMovementPerRound <=
        SCREEN_WIDTH - sizing_enemyMovementRange
      ) {
        enemiesGoingRight = true;
        didDirectionShift = true;
      }
    }

    enemies.forEach(function (enemy) {
      if (didDirectionShift) {
        enemy.y = enemy.y + sizing_enemyYMovementPerRound;
      } else {
        if (enemiesGoingRight) {
          enemy.x = enemy.x + sizing_enemyMovementPerRound;
        } else {
          enemy.x = enemy.x - sizing_enemyMovementPerRound;
        }
      }

      if (didEnemyFire()) {
        fireBullet(false, sizing_enemyWidth, enemy.x, enemy.y, "red");
      }
    });
  }
}

function didEnemyFire() {
  var random = Math.random() * 100;
  return ENEMY_FIRE_CHANCE > random;
}

function fireBullet(
  playerBullet,
  firingObjectWidth,
  firingObjectX,
  firingObjectY,
  colour
) {
  if (
    bullets.filter((bullet) => bullet.playerBullet).length <
      MAXIMUM_BULLET_COUNT ||
    !playerBullet
  ) {
    var bullet = {
      x: firingObjectX + (firingObjectWidth - sizing_bulletWidth) / 2,
      y: firingObjectY,
      bulletLength: sizing_bulletHeight,
      bulletWidth: sizing_bulletWidth,
      playerBullet: playerBullet,
      status: 0,
      draw: function () {
        c.fillStyle = colour;
        c.beginPath();
        c.fillRect(this.x, this.y, sizing_bulletWidth, sizing_bulletHeight);
        c.stroke();
      },
    };
    bullets.push(bullet);
  }
}

function bulletAnimationTick() {
  if (bullets.length > 0) {
    bullets.forEach(function (bullet) {
      if (bullet.playerBullet) {
        bullet.y = bullet.y - sizing_bulletTravelDistance;
      } else {
        bullet.y = bullet.y + sizing_bulletTravelDistance;
      }
    });
    bullets = bullets.filter(
      (bullet) =>
        (bullet.y > 0 && bullet.playerBullet) ||
        (bullet.y < SCREEN_HEIGHT && !bullet.playerBullet)
    );
  }
}

function checkCollisions() {
  bullets.forEach((bullet) => {
    enemies.forEach((enemy) => {
      if (enemyCollision(enemy, bullet)) {
        enemy.status = 1;
        bullet.status = 1;
        score++;
      }
    });

    barriers.forEach((barrier) => {
      if (barrierCollision(barrier, bullet)) {
        barrier.status = 1;
        bullet.status = 1;
      }
    });

    if (playerCollision(player, bullet)) {
      screenState = 3;
    }
  });

  bullets = bullets.filter((bullet) => bullet.status == 0);
  barriers = barriers.filter((barrier) => barrier.status == 0);
  enemies = enemies.filter((enemy) => enemy.status == 0);
}

function enemyCollision(enemy, bullet) {
  return (
    bullet.playerBullet &&
    enemy.x + sizing_enemyWidth >= bullet.x &&
    enemy.x <= bullet.x + sizing_bulletWidth &&
    enemy.y + sizing_enemyHeight >= bullet.y &&
    enemy.y <= bullet.y + sizing_bulletHeight
  );
}

function barrierCollision(barrier, bullet) {
  return (
    barrier.x + sizing_bulletWidth >= bullet.x &&
    barrier.x <= bullet.x + sizing_bulletWidth &&
    barrier.y + sizing_bulletHeight >= bullet.y &&
    barrier.y <= bullet.y + sizing_bulletHeight
  );
}

function playerCollision(player, bullet) {
  var hasHit = false;
  player.parts.forEach((part) => {
    if (
      !bullet.playerBullet &&
      part.calculateX(player.x) + part.width >= bullet.x &&
      part.calculateX(player.x) <= bullet.x + sizing_bulletWidth &&
      part.calculateY(player.y) + part.height >= bullet.y &&
      part.calculateY(player.y) <= bullet.y + sizing_bulletHeight
    ) {
      hasHit = true;
    }
  });
  return hasHit;
}

function drawGameplayComponents() {
  player.draw();

  let enemyImageToUse;
  if (enemyUpImage) {
    enemyImageToUse = imgEnemyUp;
  } else {
    enemyImageToUse = imgEnemyDown;
  }
  enemies.forEach(function (enemy) {
    enemy.draw(enemyImageToUse);
  });

  bullets.forEach(function (bullet) {
    bullet.draw();
  });

  barriers.forEach(function (barrier) {
    barrier.draw();
  });
}

function runWelcomePage() {
  let fontSize = SCREEN_WIDTH / 6.6;
  let currentYSpacing = 0;
  c.font = `${fontSize}px arial`;
  c.fillStyle = "#fff";
  c.fillText("Space", sizing_fullScreenTextStateX, sizing_fullScreenTextStateY);
  currentYSpacing = currentYSpacing + fontSize;
  c.fillText(
    "Invaders",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
  fontSize = SCREEN_WIDTH / 30;
  c.font = `${fontSize}px arial`;
  currentYSpacing = currentYSpacing + fontSize + 10;
  c.fillText(
    "Use left & right arrow keys to move",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
  currentYSpacing = currentYSpacing + fontSize + 10;
  c.fillText(
    "Use the F key to shoot",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
  fontSize = SCREEN_WIDTH / 20;
  c.font = `${fontSize}px arial`;
  currentYSpacing = currentYSpacing + fontSize + 10;
  c.fillText(
    "Press C key to start",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
}

function runGame() {
  // SCORE
  c.font = "14px arial";
  c.fillStyle = "#fff";
  c.fillText("SCORE: " + score, 0, 10);

  enemyAnimationTick();
  bulletAnimationTick();

  checkCollisions();

  drawGameplayComponents();
}

function runGameOver() {
  let fontSize = SCREEN_WIDTH / 6.66;
  let currentYSpacing = 0;
  c.font = `${fontSize}px arial`;
  c.fillStyle = "#fff";
  c.fillText("Game", sizing_fullScreenTextStateX, sizing_fullScreenTextStateY);
  currentYSpacing = currentYSpacing + fontSize;
  c.fillText(
    "Over",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
  fontSize = SCREEN_WIDTH / 20;
  c.font = `${fontSize}px arial`;
  currentYSpacing = currentYSpacing + fontSize + 10;
  c.fillText(
    `Well Done! You scored ${score}.`,
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
  currentYSpacing = currentYSpacing + fontSize;
  c.fillText(
    "Press c to replay.",
    sizing_fullScreenTextStateX,
    sizing_fullScreenTextStateY + currentYSpacing
  );
}

function runApplication() {
  switch (screenState) {
    case 1:
      runWelcomePage();
      break;
    case 2:
      runGame();
      break;
    case 3:
      runGameOver();
      break;
  }
}

function animate() {
  if (gameRunning) {
    c.clearRect(0, 0, canvas.width, canvas.height);
    requestAnimationFrame(animate);
    runApplication();
  }
}

export function resizeGame(width, height) {
  SCREEN_WIDTH = width;
  SCREEN_HEIGHT = height;
  calculateSizes();
  setUpGame();
  startSpaceInvaders();
}

export function startSpaceInvaders() {
  canvas = document.querySelector("canvas");
  canvas.width = SCREEN_WIDTH;
  canvas.height = SCREEN_HEIGHT;
  c = canvas.getContext("2d");
  gameRunning = true;
  animate();
}

if (gameRunning) {
  animate();
}
